// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";
import cfg from "../../../config.json"

const eventScoreEndpoint = `${cfg.vk2}/event_score`;
const weightEndpoint = `${cfg.vk2}/dashboard/settings`;

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("accessToken"),
  },
};

//Get table data
export const getWeeklyData = createAsyncThunk("appScore/getWeeklyData", async ({ page, sort_by, sort_order, event_type, time_range, event_category }) => {
  try {

    const showPerPageStorage = localStorage.getItem('rowsPerPage');
    const showPerPage = showPerPageStorage ? parseInt(showPerPageStorage) : 10;

    const showEventName = localStorage.getItem("weeklyEventSearch");
    const eventName = showEventName ? showEventName : ""

    const response = await axios.post(eventScoreEndpoint, {
      page,
      per_page: showPerPage ? showPerPage : 10,
      sort_by,
      sort_order,
      event_name: eventName,
      event_type,
      time_range,
      event_category
    }, config);

    const { events, count } = response.data; 

    return {
      data: events,
      allData: count,
      totalPages: Math.ceil(count / showPerPage), 
      params: { page, per_page: showPerPage, sort_by, sort_order, event_name: eventName, event_type, time_range: "week", event_category }, // category_type
    };
  } catch (error) {
    console.log(error);
    throw error; 
  }
});

export const getMonthlyData = createAsyncThunk("appScore/getMonthlyData", async ({ page, sort_by, sort_order, event_type, time_range }) => {
  try {

    const showPerPageStorage = localStorage.getItem('monthlyRowsPerPage');
    const showPerPage = showPerPageStorage ? parseInt(showPerPageStorage) : 10;

    const showEventName = localStorage.getItem("monthlyEventSearch");
    const eventName = showEventName ? showEventName : ""

    const response = await axios.post(eventScoreEndpoint, {
      page,
      per_page: showPerPage ? showPerPage : 10,
      sort_by,
      sort_order,
      event_name: eventName,
      event_type,
      time_range
    }, config);

    const { events, count } = response.data; 

    return {
      data: events,
      allData: count,
      totalPages: Math.ceil(count / showPerPage), 
      params: { page, per_page:showPerPage, sort_by, sort_order, event_name:eventName, event_type, time_range: "month" },
    };
  } catch (error) {
    console.log(error);
    throw error; 
  }
});

export const getSixMonthData = createAsyncThunk("appScore/getSixMonthData", async ({ page, sort_by, sort_order, event_type, time_range }) => {
  try {

    const showPerPageStorage = localStorage.getItem('sixMonthRowsPerPage');
    const showPerPage = showPerPageStorage ? parseInt(showPerPageStorage) : 10;

    const showEventName = localStorage.getItem("sixMonthEventSearch"); 
    const eventName = showEventName ? showEventName : ""

    const response = await axios.post(eventScoreEndpoint, {
      page,
      per_page: showPerPage ? showPerPage : 10,
      sort_by,
      sort_order,
      event_name: eventName,
      event_type,
      time_range
    }, config);

    const { events, count } = response.data; 

    return {
      data: events,
      allData: count,
      totalPages: Math.ceil(count / showPerPage), 
      params: { page, per_page: showPerPage, sort_by, sort_order, event_name: eventName, event_type, time_range: "six_month" },
    };
  } catch (error) {
    console.log(error);
    throw error; 
  }
});

export const getYearlyData = createAsyncThunk("appScore/getYearlyData", async ({ page, sort_by, sort_order, event_type, time_range }) => {
  try {

    const showPerPageStorage = localStorage.getItem('yearlyRowsPerPage');
    const showPerPage = showPerPageStorage ? parseInt(showPerPageStorage) : 10;

    const showEventName = localStorage.getItem("yearlyEventSearch"); 
    const eventName = showEventName ? showEventName : ""

    const response = await axios.post(eventScoreEndpoint, {
      page,
      per_page: showPerPage ? showPerPage : 10,
      sort_by,
      sort_order,
      event_name: eventName,
      event_type,
      time_range
    }, config);

    const { events, count } = response.data; 

    return {
      data: events,
      allData: count,
      totalPages: Math.ceil(count / showPerPage), 
      params: { page, per_page:showPerPage, sort_by, sort_order, event_name:eventName, event_type, time_range: "year" },
    };
  } catch (error) {
    console.log(error);
    throw error; 
  }
});

// Update Weights
export const updateWeights = createAsyncThunk(
  "appScore/updateWeights",
  async (weights) => {
    try {
      const response = await axios.post(weightEndpoint, weights);
      // console.log(response);
    
      return {
        weights: response.data,
      };
    } catch (error) {
      console.log(error);
    }
  }
);

export const updateScores = createAsyncThunk(
  "appScore/updateScores",
  async () => {
    try {
      const response = await axios.put(eventScoreEndpoint);

      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

// Get Weights
export const getWeights = createAsyncThunk("appScore/getWeights", async () => {
  try {
    const response = await axios.get(weightEndpoint);
    return {
      weights: response.data,
    };
  } catch (error) {
    console.log(error);
  }
});

export const initialState = {
  weeklyData: {
    data: [],
    isLoading: false,
    isWeeklyFirstFetch: true,
    total: 1,
    params: {},
    weights: {
      theater_weight: 0,
      concert_weight: 0,
      sport_weight: 0,
      time_left_weight: 0,
      venue_capacity_weight: 0,
    },
    allData: [],
    error: "",
  },

  monthlyData: {
    data: [],
    isLoading: false,
    isMonthlyFirstFetch: true,
    total: 1,
    params: {},
    weights: {
      theater_weight: 0,
      concert_weight: 0,
      sport_weight: 0,
      time_left_weight: 0,
      venue_capacity_weight: 0,
    },
    allData: [],
    error: "",
  },

  sixMonthData: {
    data: [],
    isLoading: false,
    isSixMonthFirstFetch: true,
    total: 1,
    params: {},
    weights: {
      theater_weight: 0,
      concert_weight: 0,
      sport_weight: 0,
      time_left_weight: 0,
      venue_capacity_weight: 0,
    },
    allData: [],
    error: "",
  },

  yearlyData: {
    data: [],
    isLoading: false,
    isYearlyFirstFetch: true,
    total: 1,
    params: {},
    weights: {
      theater_weight: 0,
      concert_weight: 0,
      sport_weight: 0,
      time_left_weight: 0,
      venue_capacity_weight: 0,
    },
    allData: [],
    error: "",
  },
};

export const appScoreSlice = createSlice({
  name: "appScore",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWeeklyData.pending, (state) => {
      state.weeklyData.isLoading = true;
    });
    builder.addCase(getMonthlyData.pending, (state) => {
      state.monthlyData.isLoading = true;
    });
    builder.addCase(getWeeklyData.fulfilled, (state, action) => {
      state.weeklyData.isLoading = false;
      state.weeklyData.data = action.payload.data;
      state.weeklyData.allData = action.payload.allData;
      state.weeklyData.total = action.payload.totalPages;
      state.weeklyData.params = action.payload.params;
      state.weeklyData.isWeeklyFirstFetch = false;
    });
    builder.addCase(getMonthlyData.fulfilled, (state, action) => {
      state.monthlyData.isLoading = false;
      state.monthlyData.data = action.payload.data;
      state.monthlyData.allData = action.payload.allData;
      state.monthlyData.total = action.payload.totalPages;
      state.monthlyData.params = action.payload.params;
      state.monthlyData.isMonthlyFirstFetch = false; 
    });
    builder.addCase(getSixMonthData.fulfilled, (state, action) => {
      state.sixMonthData.isLoading = false;
      state.sixMonthData.data = action.payload.data;
      state.sixMonthData.allData = action.payload.allData;
      state.sixMonthData.total = action.payload.totalPages;
      state.sixMonthData.params = action.payload.params;
      state.sixMonthData.isSixMonthFirstFetch = false; 
    });
    builder.addCase(getYearlyData.fulfilled, (state, action) => {
      state.yearlyData.isLoading = false;
      state.yearlyData.data = action.payload.data;
      state.yearlyData.allData = action.payload.allData;
      state.yearlyData.total = action.payload.totalPages;
      state.yearlyData.params = action.payload.params;
      state.yearlyData.isYearlyFirstFetch = false; 
    });
    builder.addCase(getMonthlyData.rejected, (state, action) => {
      console.log('error case triggered')
      state.monthlyData.isLoading = false;
      state.monthlyData.error = action.error.message;
    });
    builder.addCase(getSixMonthData.rejected, (state, action) => {
      console.log('error case triggered')
      state.sixMonthData.isLoading = false;
      state.sixMonthData.error = action.error.message;
    });
    builder.addCase(getWeeklyData.rejected, (state, action) => {
      console.log('error case triggered')
      state.weeklyData.isLoading = false;
      state.weeklyData.error = action.error.message;

    });
    builder.addCase(getYearlyData.rejected, (state, action) => {
      console.log('error case triggered')
      state.yearlyData.isLoading = false;
      state.yearlyData.error = action.error.message;
    });
    builder.addCase(updateWeights.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateWeights.fulfilled, (state, action) => {
      state.isLoading = false;
      state.weights = action.payload.weights;
    });
    builder.addCase(updateScores.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateWeights.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    builder.addCase(getWeights.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getWeights.fulfilled, (state, action) => {
      state.isLoading = false;
      state.weights = action.payload.weights;
    });
    builder.addCase(getWeights.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default appScoreSlice.reducer;